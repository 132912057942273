import AppLayout from "../../AppLayout";
import { useGetMe } from "../../queries";
import { User } from "../../types";
import { config } from "../../Constants";
import { NutritionComponent } from "./NutritionComponent";
import { Link } from "react-router-dom";

function FoodMenuComponent({ user }: { user: User }) {
  const user_experiments = user.experiments || "";
  const bgColor = user_experiments.includes("alt-color-061024")
    ? "bg-gradient-to-r from-[#9E4330] via-[#B2573A] to-[#C0683F]"
    : "bg-gradient-to-r from-[#006769] to-[#40A578]";
  return (
    <div className="flex flex-col bg-main-white">
      <div className={`w-full pt-[74px] border-b border-main-white ${bgColor}`}>
        <div className="w-full h-4 bg-main-white rounded-t-lg" />
      </div>
      <div
        className="bg-main-white flex-grow overflow-y-auto pb-20 pt-2 px-4"
        id="profile_page_content"
      >
        {user.weeklyPlan && <NutritionComponent user={user} />}
        {!user.weeklyPlan && (
          <div className="font-semibold text-center">
            <p>
              <Link className="text-blue-link underline" to="/chat">
                Work with your coach
              </Link>
            </p>
            <p>to create your weekly program!</p>
          </div>
        )}
      </div>
    </div>
  );
}

function NutritionPage() {
  const { data: user, isLoading: userIsLoading } = useGetMe();

  if (userIsLoading)
    return <div className="bg-main-white p-4">Loading user data...</div>;

  if (!user) {
    window.location.href = `${config.url.API_URL}/login?page=/food`;
    return <></>;
  }

  return (
    <AppLayout user={user}>
      <FoodMenuComponent user={user} />
    </AppLayout>
  );
}

export default NutritionPage;
