import { Preferences } from "@capacitor/preferences";
import { config, PLATFORM } from "../Constants";

export async function getAuthToken() {
  if (config.CURRENT_PLATFORM === PLATFORM.ANDROID) {
    const { value } = await Preferences.get({ key: "auth_token" });

    return value ? JSON.parse(value) : null;
  }
  return null;
}

export function isMobilePlatform() {
  return config.CAPACITOR.isNativePlatform();
}
