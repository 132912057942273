import { Capacitor } from "@capacitor/core";

var API_URL = process.env.REACT_APP_API_URL;
if (!API_URL) {
  if (process.env.NODE_ENV === "development") API_URL = "http://localhost:5000";
  else API_URL = "https://api.personalcoach.app";
}

var GOD_MODE = process.env.REACT_APP_GOD_MODE === "true";
if (!GOD_MODE) {
  if (process.env.NODE_ENV === "development") GOD_MODE = true;
  else GOD_MODE = false;
}

export const PLATFORM = Object.freeze({
  ANDROID: "android",
  WEB: "web",
  IOS: "ios",
});

const CURRENT_PLATFORM =
  PLATFORM[Capacitor.getPlatform().toUpperCase()] || PLATFORM.WEB;

export const config = {
  url: {
    API_URL: API_URL,
  },
  GOD_MODE: GOD_MODE,
  CURRENT_PLATFORM: CURRENT_PLATFORM,
  CAPACITOR: Capacitor,
};
