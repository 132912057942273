import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { config, PLATFORM } from "../Constants";
import { App } from "@capacitor/app";
import { useExchangeCodeForToken } from "./queries";
import { PluginListenerHandle } from "@capacitor/core";

export function useDeepLinkHandler() {
  const navigate = useNavigate();
  const exchangeCodeMutation = useExchangeCodeForToken();

  useEffect(() => {
    if (config.CURRENT_PLATFORM === PLATFORM.ANDROID) {
      const handleAppUrlOpen = async ({ url }: { url: string }) => {
        const urlObj = new URL(url);
        const oneTimeCode = urlObj.searchParams.get("one_time_code");
        const page = urlObj.searchParams.get("page") || "/chat";

        if (oneTimeCode) {
          try {
            await exchangeCodeMutation.mutateAsync(oneTimeCode);

            navigate(page);
          } catch (error) {
            console.error("Failed to exchange code for token:", error);
            navigate("/");
          }
        }
      };

      let appListener: PluginListenerHandle;

      const addListener = async () => {
        appListener = await App.addListener("appUrlOpen", handleAppUrlOpen);
      };

      addListener();

      return () => {
        if (appListener) {
          appListener.remove();
        }
      };
    }
  }, []);
}
