import { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { WeeklyProgramDetailsModalWithAudio } from "../../components/WeeklyProgram";
import {
  DailyPlanDoneStatusButton,
  DoneStatusButtonStyle,
} from "../../components/TaskDoneStatus";
import parse from "html-react-parser";
import { PaywallInlineIcon } from "../../components/Buttons";
import { PaywallButton } from "../../components/PaywallModal";

function DailyPlanMessage({
  day,
  week,
  active,
  tasks,
  isFirstDay,
}: {
  day: string;
  week: string;
  active: boolean;
  tasks: string[];
  isFirstDay: boolean | undefined;
}) {
  const [showDetails, setShowDetails] = useState<boolean>(false);

  const queryClient = useQueryClient();
  const resetCacheAndShowDetails = () => {
    if (!showDetails) {
      queryClient.resetQueries({
        queryKey: ["weekly-plan", day, "details"],
        exact: true,
      });
    }
    setShowDetails(true);
  };

  return (
    <>
      <WeeklyProgramDetailsModalWithAudio
        day={showDetails ? day : undefined}
        onClose={() => setShowDetails(false)}
      />
      <div className="w-full pb-1">
        <h1 className="text-left text-lg font-bold pl-3 mb-1">
          Today's exercise
        </h1>
        {tasks.length !== 1 && (
          <ul>
            {Array.isArray(tasks) && tasks.length > 0 ? (
              tasks.map((task, index) => (
                <li className="text-gray-800 text-sm" key={index}>
                  {parse(task)}
                </li>
              ))
            ) : (
              <p className="text-gray-500 text-sm">No tasks available</p>
            )}
          </ul>
        )}
        {tasks.length === 1 && (
          <div className="pl-3 text-gray-800 text-sm">{parse(tasks[0])}</div>
        )}
        {active && (
          <div className="flex flex-row justify-between mt-2 pl-3">
            <PaywallButton
              enablePaywall={!isFirstDay}
              onClick={resetCacheAndShowDetails}
            >
              {!isFirstDay && <PaywallInlineIcon color="#757575" suffix=" " />}
              <span className="underline cursor-pointer text-gray-600 text-left flex-1">
                Details
              </span>
            </PaywallButton>

            <DailyPlanDoneStatusButton
              day={day}
              week={week}
              style={DoneStatusButtonStyle.DailyTaskChatMessage}
              requiresSubscription={!isFirstDay}
            />
          </div>
        )}
      </div>
    </>
  );
}

export default DailyPlanMessage;
