import { stat } from "fs";
import { ReactNode } from "react";

export type Gender = "male" | "female" | "other";
export type NotificationChannel = "sms" | "email" | "none";
export interface User {
  id: string;
  profileImg: string;
  name: string;
  gender: Gender;
  age: number;
  goal: string;
  currentWeightKg: number | undefined;
  currentWeightLbs: number | undefined;
  targetWeightKg: number | undefined;
  targetWeightLbs: number | undefined;
  heightCm: number | undefined;
  heightFt: string | undefined;
  successBarrier: string;
  otherComment: string;
  sessionsPerWeek: number;
  fires: number;
  weeklyPlan: WeeklyPlan;
  email: string | undefined;
  phone: string | undefined;
  buddies: Buddy[];
  stripeCustomerId: string | undefined;
  notificationChannel?: NotificationChannel | null;
  previousWeeksStreak: number;
  isRewardedThisWeekStreak: boolean;
  level: number;
  levelTitle: string;
  isRewardedCurrentLevel: boolean;
  currentLevelFireRequirement: number;
  nextLevelFireRequirement: number;
  articles: Article[];
  experiments: string[];
  notificationEnabled: boolean;
}

export function isUserSubscribed(user: User): boolean {
  return user.stripeCustomerId != null;
}

export type WeeklyPlanDay = {
  tasks: string[];
  done: boolean;
  isFirstDay?: boolean;
  met?: number[];
  duration?: number[];
};

export interface WeeklyPlanDays {
  Monday?: WeeklyPlanDay;
  Tuesday?: WeeklyPlanDay;
  Wednesday?: WeeklyPlanDay;
  Thursday?: WeeklyPlanDay;
  Friday?: WeeklyPlanDay;
  Saturday?: WeeklyPlanDay;
  Sunday?: WeeklyPlanDay;
}
export interface WeeklyPlan {
  week: Date;
  days: WeeklyPlanDays;
}

export interface Message {
  id: number;
  conversationId: number;
  messengerRole: "user" | "coach";
  content?: string;
  formattedContent: ReactNode;
  messageType?: "text" | "form" | "plan" | "call_to_action" | "daily_task";
  callback?: string;
  submitted: boolean;
  postedAt: Date | undefined;
  // Coach responses that are added to the list of messages through optimistic
  // updates are marked as new. The same message is later fetched from the
  // server and the 'new' flag is removed. It is used to scroll to the top of
  // the new messages, and not to the very bottom.
  isNew?: boolean;
  suggestionChips?: string[];
}

export interface Buddy {
  name: string;
  email: string;
}

export const DAYS = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

export interface NutritionContent {
  fatTotal: number | null;
  saturatedFat: number | null;
  transFat: number | null;
  monounsaturatedFat: number | null;
  polyunsaturatedFat: number | null;
  carbsTotal: number | null;
  carbsNet: number | null;
  fiber: number | null;
  sugars: number | null;
  addedSugar: number | null;
  protein: number | null;
  cholesterol: number | null;
  water: number | null;
}

export interface Recipe {
  name: string;
  id: string;
  url: string;
  imageUrl: string | null;
  healthLabels: string[];
  dietLabels: string[];
  cautions: string[];
  cuisineType: string[];
  mealType: string[];
  dishType: string[];
  ingredients: string[];
  preparationTime: number | null;
  caloriesPer100g?: number;
  nutritionContentPer100g?: NutritionContent;
}

export interface Menu {
  breakfast: Recipe[];
  lunch: Recipe[];
  dinner: Recipe[];
  snack: Recipe[];
}

export interface NutritionRecommendation {
  justification: string;
  menu: Menu;
}

export function hasUserReadArticle(state: ArticleState): boolean {
  return state.wasReadAt != null;
}

export interface ArticleMetadata {
  id: string;
  title: string;
  tags: string[];
  description: string;
  duration?: number;
  type: "text" | "video";
  videoId?: string; // YouTube video ID, only for type "video"
}

export interface ArticleState {
  startedDisplayingAt: Date;
  stoppedDisplayingAt: Date | null;
  isBlockedUntil: Date | null;
  wasReadAt: Date | null;
}

export interface Article {
  metadata: ArticleMetadata;
  state: ArticleState;
}
